<template>
  <div>
    <c-table
      ref="table"
      title="유해화학물질 취급현황"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="facilityStatusTable.hazardChems"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="chmFacilityStatusTableHazardId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled&&facilityStatusTable.hazardChems.length>0" label="삭제" icon="remove" @btnClicked="deleteHazardChem" />
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addHazardChem" />
          <c-btn 
            v-if="editable && !disabled && facilityStatusTable.hazardChems.length>0" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="facilityStatusTable.hazardChems"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveHazardChem"
            @btnCallback="saveHazardChemCallback" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
// import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'facility-status-table-hazard-chem',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',
      }),
    },
    facilityStatusTable: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',  // 취급시설 현황표 일련번호
        facilityStatusTableName: '',  // 현황표명
        plantCd: '',  // 사업장코드
        permitDate: '',  // 허가일
        companyName: '',  // 회사명
        managerName: '',  // 대표자
        managerEmail: '',  // 대표자 E-MAIL
        phoneNo: '',  // 전화번호
        bizNo: '',  // 사업자번호
        remarks: '',  // 비고
        attrVal1: '',  // 회사id
        attrVal2: '',  // 소재지
        attrVal3: '',  // 시설구분
        regDtStr: '',
        regUserName: '',
        managers: [], // 유해화학물질관리자
        facilities: [], // 시설
        hazardChems: [], // 유해화학물질 취급현황
        equipDevices: [], // 설비 장치
        equipPowers: [], // 설비 동력기계
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'materialName' },
        ],
        columns: [
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재',
            align: 'left',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '물질명',
            align: 'left',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'limitRepval',
            field: 'limitRepval',
            label: '함량 (%)',
            align: 'right',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'useage',
            field: 'useage',
            label: '사용처',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'annualVolumne',
            field: 'annualVolumne',
            label: '연간취급량 (톤/년)<br/>(허가증상)',
            align: 'right',
            style: 'width:100px',
            sortable: true,
            type: 'number',
          },
          {
            name: 'previousVolumne',
            field: 'previousVolumne',
            label: '과년도취급량 (톤/년)<br/>(실적보고서상)',
            align: 'right',
            style: 'width:100px',
            sortable: true,
            type: 'number',
          },
          {
            name: 'propertiesStateName',
            field: 'propertiesStateName',
            label: '저장성상 (액체/고상/기상)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'storageMethod',
            field: 'storageMethod',
            label: '저장방법',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'text',
          },
        ],
        data: [],
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      isSave: false,
      saveUrl: '', 
      deleteUrl: '', 
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let _height = this.contentHeight ? this.contentHeight - 100 : 700
      return _height + 'px';
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.chm.facilityStatusTable.hazard.save.url;
      this.deleteUrl = transactionConfig.chm.facilityStatusTable.hazard.delete.url;
    },
    deleteHazardChem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.facilityStatusTable.hazardChems = this.$_.reject(this.facilityStatusTable.hazardChems, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addHazardChem() {
      this.popupOptions.title = '유해화학물질';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.facilityStatusTable.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/common/hazardChem/hazardChemPop.vue'}`);
      this.popupOptions.width = '75%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.facilityStatusTable.hazardChems, { mdmChemId: item.mdmChemId, }) === -1) {
            this.facilityStatusTable.hazardChems.splice(0, 0, { 
              chmFacilityStatusTableHazardId: uid(),  // 취급시설 현황표 유해화학물질 일련번호
              chmFacilityStatusTableId: this.popupParam.chmFacilityStatusTableId,  // 취급시설 현황표 일련번호
              mdmChemId: item.mdmChemId,  // 조성품 일련번호
              useage: '',  // 사용처
              annualVolumne: '',  // 연간취급량
              previousVolumne: '',  // 과년도취급량
              storageMethod: '',  // 저장방법
              materialName: item.materialName,
              chemNmEn: item.chemNmEn,
              casNo: item.casNo,
              limitRepval: item.limitRepval,
              propertiesStateName: item.propertiesStateName,
              editFlag: 'C', regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    saveHazardChem() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.isSave = !this.isSave
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveHazardChemCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail');
    },
  }
};
</script>